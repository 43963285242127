import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './app-autocomplete.component.html',
  styleUrls: ['./app-autocomplete.component.scss']
})
export class AppAutocompleteComponent {

  @Input() label = 'label';
  @Input() placeholder = 'placeholder';
  @Input() hintStart!: string;
  @Input() hintEnd!: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() multiple = false;
  @Input() type = 'string';
  @Input() options: any[] = [];
  @Input() propertyViewKey = '';
  @Input() property: any;
  @Input() compareKey = 'id';
  @Output() propertyChange = new EventEmitter<any>();
  @Output() controlValidity = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();
  formControl = new FormControl('', []);
  validators: ValidatorFn[] = [];



  constructor(public utilService: UtilService) {

  }

  ngOnInit() {
    if (this.required) {
      this.validators.push(Validators.required);
    }
    this.formControl = new FormControl({value: '', disabled: this.disabled}, this.validators);
  }

  changeModel(event: any) {
    this.propertyChange.emit(event);
    this.controlValidity.emit(this.formControl.errors ? false : true);
  }

  onSelectionChange(event: any) {
    this.selectionChange.emit(event);
  }

  compareFunction = (o1: any, o2: any) => {
    if (typeof o1 === 'string' && typeof o2 === 'string') {
      return o1 === o2;
    } else {
      if (o1 && o2) {
        return o1[this.compareKey] === o2[this.compareKey];
      }
    }
    return false;
  }

}
