import { Injectable, QueryList } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, BehaviorSubject } from 'rxjs';
import { NgxSpinnerService, Spinner } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';
import { AppSnackbarComponent } from '../components/app-snackbar/app-snackbar.component';
import { NgxFileDropEntry } from 'ngx-file-drop';
@Injectable({
    providedIn: 'root'
})
export class UtilService {
    private title = new BehaviorSubject<String>('');
    private title$ = this.title.asObservable();
    private route = new BehaviorSubject<String>('');
    private route$ = this.route.asObservable();
    public refreshEvent = new BehaviorSubject<String>('');
    public refreshEvent$ = this.refreshEvent.asObservable();
    private previousRouteObject = null;
    private spinnerConfig: Spinner = {
        type: 'square-jelly-box',
        size: 'medium',
        bdColor: 'rgba(51,51,51,0.3)',
        color: getComputedStyle(document.body).getPropertyValue('--hoverPrimaryColor') ? getComputedStyle(document.body).getPropertyValue('--hoverPrimaryColor') : 'white',
        fullScreen: false,
    }
    chartColors = ['#62d997', '#d9814c', '#4cc8d9', '#96a5b8'];
    loadingData = false;
    private timerDelay: any = null;
    spinnerShowCounter = 0;

    constructor(
        private snackBar: MatSnackBar,
        private spinner: NgxSpinnerService,
        public translate: TranslateService
    ) { }

    setTitle(title: String) {
        this.title.next(title);
    }

    getTitle(): Observable<String> {
        return this.title$;
    }

    setRoute(route: String) {
        this.route.next(route);
    }

    getRoute(): Observable<String> {
        return this.route$;
    }

    setPreviousRouteObject(object: any) {
        this.previousRouteObject = object;
    }

    getPreviousRouteObject() {
        return this.previousRouteObject;
    }

    resetPreviousRouteObject() {
        this.previousRouteObject = null;
    }

    openSnackbar(message: string, icon?: string, title?: string, iconSymbol = false, action = null) {
        return this.snackBar.openFromComponent(AppSnackbarComponent, {
            duration: 5000,
            panelClass: 'app-snackbar',
            verticalPosition: 'top',
            horizontalPosition: 'right',
            data: {
                message: message,
                title: title,
                icon: icon,
                iconSymbol: iconSymbol
            }
        });
    }

    showSpinner(container = false) {
        this.spinnerShowCounter++;
        this.loadingData = true;
        if (container) {
            this.spinner.show('container-spinner', this.spinnerConfig);
        } else {
            this.spinner.show('external-spinner', this.spinnerConfig);
        }
    }

    hideSpinner(container = false) {
        if (this.spinnerShowCounter > 0) {
            this.spinnerShowCounter--;
        } 
        if (this.spinnerShowCounter <= 0) {
            this.spinnerShowCounter = 0;
            if (container) {
                this.spinner.hide('container-spinner');
            } else {
                this.spinner.hide('external-spinner');
            }
            this.loadingData = false;
        }
        // if (container) {
        //     this.spinner.hide('container-spinner');
        // } else {
        //     this.spinner.hide('external-spinner');
        // }
        // this.loadingData = false;  
    }

    setSpinnerColor(color: string) {
        this.spinnerConfig.color = color;
    }

    validateForm(formFields: QueryList<any>) {
        if (formFields != null) {
            for (let formField of formFields) {
                formField?.formControl?.markAllAsTouched();
                if (formField?.formControl?.status === 'INVALID') {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    niceBytes(x) {
        if (x) {
          const units = ['bytes', 'KB', 'MB', 'GB'];
          let l = 0, n = parseInt(x, 10) || 0;

          while (n >= 1024 && ++l) {
            n = n / 1024;
          }
          return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        } else {
          return '';
        }
    }

    async delay(ms: number) {
        return new Promise(resolve => this.timerDelay = setTimeout(resolve, ms));
    }

    async droppedFile(fileDropped: NgxFileDropEntry[], document: string) {
        let attachmentList = [];
        if (fileDropped[0].fileEntry.isFile) {
            const fileEntry = fileDropped[0].fileEntry as FileSystemFileEntry;
            const result = await new Promise((resolve, reject) => {
                fileEntry.file((file: File) => {
                    if (file) {
                        // !this.appConfigService.maxUploadSize || file.size <= this.appConfigService.maxUploadSize
                        if (true) {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = async () => {
                                let base64Content: any = reader.result;
                                base64Content = base64Content.split('base64,');
                                base64Content = base64Content.pop();

                                const attachment = {
                                    fileName: file.name,
                                    documentName: document,
                                    content: base64Content,
                                    file: file,
                                    fileType: file.type,
                                    fileExtension: file.name.split('.').pop(),
                                    fileSize: file.size,
                                    size: this.niceBytes(file.size),
                                    isRename: false,
                                };

                                attachmentList.push(attachment);
                                resolve(attachmentList);

                            };
                            reader.onerror = (error) => {
                                const msg = 'Error on file reading: ' + error;
                                this.openSnackbar(msg);
                                reject(null);
                            };
                        } else {
                            const msg = 'Max upload limit exceeded';
                            this.openSnackbar(msg);
                            reject(null);
                        }
                    }
                });
            });
            return result;
        }
    }

    async droppedFiles(filesDropped: NgxFileDropEntry[]) {
        let attachmentList = [];
        for (let fileDropped of filesDropped) {
            if (fileDropped.fileEntry.isFile) {
                const fileEntry = fileDropped.fileEntry as FileSystemFileEntry;
                const result = await new Promise((resolve, reject) => {
                    fileEntry.file((file: File) => {
                        if (file) {
                            // !this.appConfigService.maxUploadSize || file.size <= this.appConfigService.maxUploadSize
                            if (true) {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = async () => {
                                    let base64Content: any = reader.result;
                                    base64Content = base64Content.split('base64,');
                                    base64Content = base64Content.pop();

                                    const attachment = {
                                        fileName: file.name,
                                        content: base64Content,
                                        file: file,
                                        fileType: file.type,
                                        fileExtension: file.name.split('.').pop(),
                                        fileSize: file.size,
                                        size: this.niceBytes(file.size),
                                        isRename: false,
                                    };

                                    attachmentList.push(attachment);
                                    resolve(attachmentList);

                                };
                                reader.onerror = (error) => {
                                    const msg = 'Error on file reading: ' + error;
                                    this.openSnackbar(msg);
                                    reject(null);
                                };
                            } else {
                                const msg = 'Max upload limit exceeded';
                                this.openSnackbar(msg);
                                reject(null);
                            }
                        }
                    });
                });
            }
        }
        return attachmentList;
    }

    base64toBlob(base64Data, contentType) {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters?.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
          const begin = sliceIndex * sliceSize;
          const end = Math.min(begin + sliceSize, bytesLength);

          const bytes = new Array(end - begin);
          for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
          }
          byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }
}
