import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-bar',
  templateUrl: './app-action-bar.component.html',
  styleUrls: ['./app-action-bar.component.scss']
})
export class AppActionBarComponent {

  @Input() type = 'view';
  @Input() normalTitle = false;
  @Input() canCreate = false;
  @Input() canEdit = false;
  @Input() canSave = false;
  @Input() canCancel = true;
  @Input() enableSave = true;
  @Input() canForward = false;
  @Input() enableForward = true;
  @Input() enableCancel = true;
  @Input() onlyIcon = true;
  @Input() onlyBack = false;
  @Input() stroked = false;
  @Input() size = 'medium';
  @Input() noPadding = false;
  @Input() noMargin = false;
  @Input() forwardLabel = 'forward';
  @Input() saveLabel = 'save';
  @Output() createEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() saveEvent = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<any>();
  @Output() backEvent = new EventEmitter<any>();
  @Output() forwardEvent = new EventEmitter<any>();

  onSaveClick(event: any) {
    if (this.enableSave) {
      this.saveEvent.emit(event);
    }
  }

  onCreateClick(event: any) {
    this.createEvent.emit(event);
  }

  onEditClick(event: any) {
    this.editEvent.emit(event);
  }

  onBackClick(event: any) {
    this.backEvent.emit(event);
  }

  onCancelClick(event: any) {
    this.cancelEvent.emit(event);
  }

  onForwardClick(event: any) {
    if (this.enableForward) {
      this.forwardEvent.emit(event);
    }
  }
}
