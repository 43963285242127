<!-- Type string -->
<mat-form-field *ngIf="type === 'string'" class="app-select" appearance="outline" [ngClass]="{'read-only': readonly, 'small' : small}">
    <mat-label *ngIf="!noFloat">{{label}}</mat-label>
    <mat-select placeholder="{{placeholder}}" [formControl]="formControl"
        required="{{required}}" [ngModel]="property" multiple="{{multiple}}"
        (ngModelChange)="changeModel($event)" [compareWith]="compareFunction">
        <mat-option *ngFor="let option of options" [value]="option" [ngClass]="{'small': small}">{{translateViewProperty ? (option | translate) : option}}</mat-option>
    </mat-select>
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type object -->
<mat-form-field *ngIf="type === 'object'" class="app-select" appearance="outline" [ngClass]="{'read-only': readonly}">
  <mat-label>{{label}}</mat-label>
  <mat-select placeholder="{{placeholder}}" [formControl]="formControl" required="{{required}}" [ngModel]="property"
    multiple="{{multiple}}" (ngModelChange)="changeModel($event)" (selectionChange)="onSelectionChange($event)" [compareWith]="compareFunction">
    <mat-select-trigger *ngIf="showCount">
      <span *ngIf="property?.length > 0" class="count-number">
        {{property?.length}}
      </span>
      <span *ngFor="let prop of property; last as isLast">{{prop[propertyViewKey]}}{{isLast ? '' : ', '}}</span>
    </mat-select-trigger>
    <mat-option *ngFor="let option of options" [value]="option">{{option[propertyViewKey]}}</mat-option>
  </mat-select>
  <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
  <mat-hint align="end">{{hintEnd}}</mat-hint>
  <mat-error *ngIf="formControl.hasError('required')">
    {{ 'field_required' | translate }}
  </mat-error>
</mat-form-field>

<!-- Type object -->
<mat-form-field *ngIf="type === 'objectIds'" class="app-select" appearance="outline"
  [ngClass]="{'read-only': readonly}">
  <mat-label>{{label}}</mat-label>
  <mat-select placeholder="{{placeholder}}" [formControl]="formControl" required="{{required}}" [ngModel]="property"
    multiple="{{multiple}}" (ngModelChange)="changeModel($event)" (selectionChange)="onSelectionChange($event)" [compareWith]="compareFunction">
    <mat-select-trigger *ngIf="showCount">
      <span *ngIf="property?.length > 0" class="count-number">
        {{property?.length}}
      </span>
      <span *ngFor="let prop of property; last as isLast">{{getObject(prop)}}{{isLast ? '' : ', '}}</span>
    </mat-select-trigger>
    <mat-option *ngFor="let option of options" [value]="option.id">{{option[propertyViewKey]}}</mat-option>
  </mat-select>
  <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
  <mat-hint align="end">{{hintEnd}}</mat-hint>
  <mat-error *ngIf="formControl.hasError('required')">
    {{ 'field_required' | translate }}
  </mat-error>
</mat-form-field>

<!-- Type searchbar -->
<mat-form-field *ngIf="type === 'searchbar'" class="app-select-searchbar align-items-center"  appearance="outline"
  [ngClass]="{'read-only': readonly}">
  <mat-label *ngIf="!noFloat">{{label}}</mat-label>
  <mat-select placeholder="{{placeholder}}" [formControl]="formControl" required="{{required}}" [ngModel]="property"
    multiple="{{multiple}}" (ngModelChange)="changeModel($event)" [compareWith]="compareFunction">
    <mat-option *ngFor="let option of options" [value]="option.value">{{option.label}}</mat-option>
  </mat-select>
  <mat-error *ngIf="formControl.hasError('required')">
       {{ 'field_required' | translate }}
  </mat-error>
</mat-form-field>


